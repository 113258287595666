* {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
}

h1 {
  font-size: 90px;
  line-height: 115%;
}

h2 {
  font-size: 80px;
}

h3 {
  font-size: 38px;
}

@media (max-width: 800px) {
  h1 {
    font-size: 12vw !important;
    text-align: center;
  }
  h2 {
    font-size: 12vw !important;
    text-align: center;
  }
}
nav {
  margin-bottom: 120px;
}
nav ul {
  list-style: none;
  display: flex;
  gap: 20px;
}
nav a {
  color: #B3B4B6;
  text-decoration: none;
  position: relative;
  padding: 3px;
}
nav a:hover {
  color: #fff;
}
nav a::after {
  content: "";
  height: 2px;
  width: 0;
  background: #006EB7;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 150ms ease-in-out;
}
nav a:hover::after {
  width: 100%;
}

.header_subheading {
  margin-top: 25px;
  color: #B3B4B6;
}

header {
  padding: 100px 25px;
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 50px;
  flex-wrap: wrap;
}

.header_text {
  width: 1000px;
}

.profile_image {
  height: 500px;
  width: 500px;
  max-height: 80vw;
  max-height: 80vw;
  overflow: hidden;
  border-radius: 50%;
  border: 20px solid #006EB7;
  display: flex;
  justify-content: center;
  align-item: center;
}
.profile_image img {
  height: 100%;
}

@media (max-width: 800px) {
  .header_subheading {
    text-align: center;
  }
}
.sec_ueber_uns {
  background-color: #1F2B33;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap-reverse;
  gap: 50px;
}
.sec_ueber_uns img {
  width: 50%;
  max-width: 1200px;
  border: 15px solid #fff;
}
.sec_ueber_uns .ueberUns_container {
  width: 800px;
  max-width: 90%;
}
.sec_ueber_uns .ueberUns_container h2 {
  font-size: 80px;
}
.sec_ueber_uns .ueberUns_container p {
  text-align: justify;
  line-height: 175%;
}

@media (max-width: 800px) {
  .sec_ueber_uns {
    padding: 50px 0;
    min-height: 0;
  }
  .sec_ueber_uns img {
    width: calc(90% - 30px);
  }
  .sec_ueber_uns h2 {
    text-align: center;
    font-size: 12vw;
  }
}
#sec_dienstleistung {
  padding: 70px 0;
  background-color: #15171C;
}
#sec_dienstleistung h2 {
  font-size: 4em;
  text-align: center;
}

.flex-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.card {
  width: 550px;
  max-width: calc(90% - 40px);
  background-color: #1F2B33;
  border-radius: 100px;
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}
.card * {
  text-align: center;
}
.card a {
  background-color: #006EB7;
  width: content;
  padding: 15px 40px;
  border-radius: 50px;
  margin: 0 auto;
  text-decoration: none;
  color: #fff;
  border: 4px solid #006EB7;
  transition: 150ms ease-in-out;
}
.card a:hover {
  background: transparent;
}

@media (max-width: 500px) {
  #sec_dienstleistung h2 {
    font-size: 12vw;
  }
  #sec_dienstleistung h3 {
    font-size: 10vw;
  }
}
footer {
  padding: 70px 0;
  background-color: #1F2B33;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
footer ul {
  list-style: none;
  display: flex;
  gap: 20px;
}
footer ul a {
  color: #B3B4B6;
  text-decoration: none;
  position: relative;
  padding: 3px;
}
footer ul a:hover {
  color: #fff;
}
footer ul a::after {
  content: "";
  height: 2px;
  width: 0;
  background: #006EB7;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 150ms ease-in-out;
}
footer ul a:hover::after {
  width: 100%;
}
footer h2 {
  font-size: 50px;
  text-align: center;
}
footer .grid-container {
  margin: 60px auto;
  width: 1800px;
  max-width: 90%;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 300px 300px;
  grid-template-areas: "item-1 item-2 item-2 item-3" "item-4 item-4 item-5 item-3";
}
footer .grid-item {
  background-color: #213E52;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
footer .grid-item a {
  text-decoration: none;
  color: #fff;
}
footer #item-1 {
  grid-area: item-1;
  text-align: center;
}
footer #item-1 img {
  width: 50%;
}
footer #item-2 {
  grid-area: item-2;
  display: flex;
  flex-direction: column;
}
footer #item-3 {
  grid-area: item-3;
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-wrap: wrap;
}
footer #item-3 img {
  width: 50%;
}
footer #item-3 h3 {
  font-size: 30px;
}
footer #item-4 {
  grid-area: item-4;
  display: flex;
  flex-direction: column;
}
footer #item-5 {
  grid-area: item-5;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer #item-5 a {
  text-align: center;
  width: 100%;
}
footer #item-5 img {
  width: 80%;
}

@media (max-width: 1300px) {
  footer {
    text-align: center;
  }
  footer h2 {
    font-size: 4em;
  }
  footer h3 {
    font-size: 1.25em;
  }
  footer .grid-container {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 300px 300px 300px;
    grid-template-areas: "item-1 item-2 item-2" "item-4 item-4 item-5" "item-3 item-3 item-3";
  }
  footer #item-3 {
    flex-direction: row;
  }
}
@media (max-width: 990px) {
  footer .grid-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: "item-1 item-5" "item-2 item-2" "item-4 item-4" "item-3 item-3";
  }
  footer #item-3 {
    flex-direction: row;
  }
}
.highlited {
  color: #006EB7;
}

body {
  background-color: #15171C;
  color: #fff;
  font-family: "Poppins", "sans-sarif";
  font-size: 20px;
}